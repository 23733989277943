html,
body {
    font-family: 'Be Vietnam Pro', sans-serif;
    font-size: 16px;
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent; //issue click in mobile flash background highligh
}

button {
    &:focus {
        outline: none;
    }
}

p {
    margin-bottom: 0;
    margin-top: 0;
}

a {
    text-decoration: none;
}

[role='button'],
button {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

input {
    word-break: normal;
}

ul,
ol {
    margin: 0;
    padding: 0;
}

.hidden-animation {
    visibility: hidden;
}

.overflow-hidden {
    height: 100vh;
}

.u-mt {
    &-24 {
        margin-top: rem(24);
    }

    &-16 {
        margin-top: rem(16);
    }
}

.notfound {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include adjust-flex();
}

body {background: #fff;
    color: #000;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 16px;
    line-height: 28px;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
li,
p {margin: 0 0 16px;}

h1 {font-size: 40px;
    line-height: 60px;}

h1,
h2 {font-weight: 700;}

h2 {font-size: 32px;
    line-height: 48px;}

h3 {font-size: 24px;
    line-height: 36px;}

h3,
h4 {font-weight: 700;}

h4 {font-size: 20px;
    line-height: 30px;}

h5,
h6 {font-size: 16px;
    line-height: 24px;
    font-weight: 700;}

a {
    text-decoration: none;
    cursor: pointer;
    color: #000;

    &:hover {
        text-decoration: underline;
    }
}

.visible {display: block;}
.hidden {display: none;}
.page {width: 100%;}

.container {position: relative;
    width: 90%;
    max-width: 1024px;
    margin: 0 auto;}.header {padding: 16px 0;}

.header .title {font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    margin: 0;}

.translations-list-container {padding-bottom: 8px;
    margin: 0 0 16px;}

.translations-list-container .translations-list {margin: 0;
    padding: 0;
    list-style: none;}

.translations-list-container .translations-list .translations-list-item {display: inline-block;
    padding: 0;
    margin: 0 8px 8px 0;
    font-weight: 700;
    color: #553df4;}

.translations-list-container .translations-list .translations-list-item a {display: inline-block;
    color: #553df4;
    border: 1px solid #553df4;
    border-radius: 32px;
    padding: 4px 16px;}

.translations-content-container {padding-top: 16px;
    border-top: 1px solid #eee;}

.footer {
    border-top: 1px solid #eee;
    margin: 32px 0 0;
    padding: 16px 0;
}

.ubey-RecordingScreen-count-down-container {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 9999999999999;
    background-color: rgba(0, 0, 0, 0.2);
}

.ubey-RecordingScreen-count-down-content {
    position: absolute;
    display: flex;
    top: 50%;
    left: 50%;
    justify-content: center;
    align-items: center;
    color: $white;
    height: 15em;
    width: 15em;
    transform: translate(-50%, -100%);
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
}

/* stylelint-disable-next-line selector-max-id */
#ubey-RecordingScreen-count-count {
    font-size: 14em;
    transform: translateY(-2%);
}
